/* eslint-disable no-underscore-dangle */
import React from 'react'
import { lightTheme, darkTheme } from '../styles/variables'

type InitialStateProps = {
  isDark: boolean
  theme: Record<string, string>
  isLoaded: boolean
  toggleTheme?: () => void
}

const initialState: InitialStateProps = {
  isDark: false,
  isLoaded: false,
  theme: lightTheme
}

const ThemeContext = React.createContext(initialState)

type Props = {}

const ThemeProvider: React.FC<Props> = ({ children }) => {
  const [dark, setDark] = React.useState(true) // Default theme is dark
  const [isLoaded, setIsLoaded] = React.useState(true) // Default theme is dark

  // On mount, read the preferred theme from the persistence
  React.useEffect(() => {
    let isDark = true
    try {
      const local = localStorage.getItem('dark')
      if (local && local.length) {
        isDark = local === 'true'
      } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        isDark = true
      }
      setIsLoaded(true)
    } catch (error) { }

    setDark(isDark)
    if (isDark) {
      document.documentElement.classList.add('darkMode')
    } else {
      document.documentElement.classList.remove('darkMode')
    }
  }, [dark])

  // To toggle between dark and light modes
  const toggleTheme = () => {
    const isDark = !dark
    localStorage.setItem('dark', JSON.stringify(isDark))
    setDark(isDark)
  }

  const theme = dark ? darkTheme : lightTheme
  return <ThemeContext.Provider value={{ theme, isLoaded, isDark: dark, toggleTheme }}>{children}</ThemeContext.Provider>
}

export { ThemeProvider, ThemeContext }
