import * as React from 'react'
import styled from '@emotion/styled'
import { dimensions } from '../styles/variables'

const StyledLayoutMain = styled.main`
  width: 100%;
  padding-left: ${dimensions.sideBar.width};
`

type LayoutMainProps = {
  className?: string
  id?: string
}

const LayoutMain: React.FC<LayoutMainProps> = ({ children, className, id }) => (
  <StyledLayoutMain id={id} className={className}>
    {children}
  </StyledLayoutMain>
)

export default LayoutMain
