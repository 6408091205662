export const createAnchorUrl = (route: string, link: string): string =>
  `${route}#${link
    .toLowerCase()
    .split(' ')
    .join('-')}`

export const createAnchor = (text: string): string =>
  `${text
    .toLowerCase()
    .split(' ')
    .join('-')}`

export const isSamePath = (path: string, route: string): boolean => path.replace(/\//g, '') === route.replace(/\//g, '') || path === route
