export const lightTheme = {
  colorPrimary: '#f15a29',
  colorSecondary: '#4d6aa7',
  onColor: '#ffffff',
  mainPrimary: '#495162',
  mainSecondary: '#8a96aa',
  mainTertiary: '#c1c5ca',
  background: '#ffffff',
  surface: '#ffffff',
  cardDefault: '#f6f6f6',
  cardSelected: '#ececec',
  statusError: '#ee3d52',
  statusWarning: '#ffa001',
  statusSuccess: '#33bb5d',
  darkOverlay: 'rgba(0,0,0,0.3)',
  lightOverlay: 'rgba(0,0,0,0.1)',
  link: '#4d6aa7',
  theme: 'light',
  cokeRed: '#F40000'
}

export const darkTheme = {
  colorPrimary: '#f15a29',
  colorSecondary: '#646464',
  onColor: '#f6f6f6',
  mainPrimary: '#f6f6f6',
  mainSecondary: '#646464',
  mainTertiary: '#c1c5ca',
  background: '#0a1929',
  surface: '#0a1929',
  cardDefault: '#060F1A',
  cardSelected: '#333333',
  statusError: '#be3142',
  statusWarning: '#cc850e',
  statusSuccess: '#29964a',
  darkOverlay: 'rgba(0,0,0,0.3)',
  lightOverlay: 'rgba(0,0,0,0.1)',
  link: '#4d6aa7',
  theme: 'dark',
  cokeRed: '#F40000'
}

export const fonts = {
  sansSerif: `Rawline, -apple-system, BlinkMacSystemFont, "Roboto", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif`,
  serif: 'Georgia, "Times New Roman", Times, serif',
  monospace: 'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace, monospace'
}

export const weights = {
  lght: 300,
  reg: 400,
  med: 600,
  bold: 900
}

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
}

export const widths = {
  sm: 480,
  md: 720,
  lg: 960,
  xl: 1300
}

export const fontSize = {
  regular: 17,
  med: 18,
  large: 20,
  xlarge: 24,
  xxxlarge: 40
}

export const dimensions = {
  fontSize,
  header: {
    width: 1260,
    minWidth: 480,
    height: 60
  },
  headingSizes: {
    h1: 45,
    h2: 34,
    h3: 28,
    h4: 17,
    h5: 16
  },
  lineHeight: {
    regular: 1.45,
    heading: 1.2
  },
  containerPadding: 48,
  sideBar: {
    width: '220px'
  }
}

export const heights = {
  header: 60
}
