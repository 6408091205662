import * as React from 'react'

import '../fonts/rawline.css'
import 'modern-normalize'

import SEO from '../components/seo'
import Navigation from '../components/navigation'
import LayoutRoot from '../components/LayoutRoot'
import LayoutMain from '../components/LayoutMain'
import Header from '../components/Header'
import { ThemeProvider } from '../contexts/themeContext'

const IndexLayout: React.FC = ({ children }) => (
  <ThemeProvider>
    <LayoutRoot>
      <SEO />
      <Navigation />
      <LayoutMain>{children}</LayoutMain>
    </LayoutRoot>
  </ThemeProvider>
)

export default IndexLayout
