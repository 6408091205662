import * as React from 'react'
import { Global } from '@emotion/core'
import styled from '@emotion/styled'
import { widths, darkTheme } from '../styles/variables'
import { getEmSize } from '../styles/mixins'
import { normalize } from '../styles/normalize'
import { ThemeContext } from '../contexts/themeContext'
import logo from '../static/sylo_o.svg'

const StyledLayoutRoot = styled.div`
  position: relative;
  max-width: ${getEmSize(widths.xl)}em;
  margin: 0 auto;
  min-height: 100vh;
`
const Loader = styled.div`
  position: fixed;
  background-color: ${darkTheme.background};
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
`
interface LayoutRootProps {
  className?: string
}

const LayoutRoot: React.FC<LayoutRootProps> = ({ children, className }) => {
  const [load, setLoad] = React.useState(true)
  const { theme, isLoaded } = React.useContext(ThemeContext)
  React.useEffect(() => {
    setTimeout(() => {
      setLoad(false)
    }, 1000)
  }, [isLoaded])

  return (
    <>
      {load ? (
        <Loader style={{ opacity: isLoaded ? 0 : 1 }}>
          <img width={60} src={logo} alt="sylo logo circle" />
        </Loader>
      ) : null}
      <Global styles={normalize(theme)} />
      <StyledLayoutRoot className={className}>{children}</StyledLayoutRoot>
    </>
  )
}

export default LayoutRoot
