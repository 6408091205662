import * as React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import { useLocation } from '@reach/router'
import logoBlue from '../static/sylo_blue_orange.svg'
import logoWhite from '../static/sylo_white_orange.svg'

import * as utils from '../utils'
import { dimensions, weights } from '../styles/variables'
import { ThemeContext } from '../contexts/themeContext'
import { getEmSize } from '../styles/mixins'

type Props = {
  allMarkdownRemark: {
    nodes: {
      id: string
      frontmatter: {
        name: string
        route: string
        menu: string
        order: string
        title: string
      }
      headings: {
        value: string
        depth: string
      }[]
    }[]
  }
}

const Navigation: React.FC = () => {
  const { theme, isDark } = React.useContext(ThemeContext)
  const LogoLink = styled(Link)`
    height: ${getEmSize(dimensions.header.height)}em;
    display: flex;
    position: relative;
    align-items: center;
    text-decoration: none;
    margin-bottom: 70px;
    img {
      height: 40px;
    }
    &:hover,
    &:focus {
      outline: 0;
      border: none;
      text-decoration: none;
    }
  `
  const Logo = styled.img`
    height: 40px;
  `

  const StyledNavigation = styled.section`
    position: fixed;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0 0 1.5rem 1.5rem;
    width: ${dimensions.sideBar.width};
    display: inline-block;
    height: 100%;
    z-index: 8;
    top:0;
    margin-top: 50px;

    ::-webkit-scrollbar {
      width: 0;
      padding: 0;
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
  `

  const AccordionList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    max-width: 30rem;
    overflow: hidden;

    .accordion-list__item {
      padding: 0 0 60px 0;
    }

    .accordion-item--opened .accordion-item__icon {
      transform: rotate(180deg);
    }
    .accordion-item--opened .accordion-item__inner {
      max-height: 100rem;
      transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition-duration: 0.5s;
      transition-property: max-height;
    }
    .accordion-item--opened .accordion-item__content {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.2s;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      transition-property: opacity, transform;
    }
    .accordion-item__line {
      display: block;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 2;
      position: relative;
    }
    .accordion-item__button {
      border: none;
      padding: 0;
      margin: 0;
      display: block;
      width: 100%;
      text-align: left;
      text-decoration: none;
      color: ${theme.mainSecondary};
      &:hover,
      &.active {
        color: ${theme.colorPrimary};
      }
    }
    .accordion-item__title {
      margin: 0;
      font-weight: ${weights.med};
      color: ${theme.mainPrimary};
      font-size: ${dimensions.headingSizes.h4}px;
      font-family: 'CentraNo2Regular', sans-serif;
      display: block;
      font-weight: 400;
      text-decoration: none;
      text-transform:uppercase;
      letter-spacing: 1px;
      &:hover,
      &.active {
        color: ${theme.colorPrimary};
      }
    }
    .accordion-item__icon {
      width: 1.2rem;
      height: 1.2rem;
      margin-right: 0.5rem;
      transition: transform 0.3s ease-in-out;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAABGklEQVR4Ae3RAcZCQRiF4buDfwshBGi+2UQgcIGAVtpSIuS/KyilG+UTcbk6zIH3GQBm3mM6AAAAAAAAAACA+eqf/yZBXcV/2XeCVPYx1FXj/FjGUMd45AQp/1HHGGLZNL+e61jHnKDmv8652YT1IvPfE2LX/Sh27/ycsF60yT/lk58JYn6eU4MJccjnlAmZ/33i0OAH4jg9Qcw/5g9YJpS+m6n0xvzpCfVe+nn59S7kGyYo+YYJWz3fO+E2PaFs9XzPhMy/6fmWCXq+YUJs9HzrhLh+JsQmrnq+bYKeb52g53snXPR88wQ93z9Bz/dP0PP9E/R89wQ93zpBz7dO0POtE/R86wQ93zpBzzdP+MoHAAAAAAAAAADAExTnTW20AtjhAAAAAElFTkSuQmCC);
      opacity: 0.6;
    }
    .accordion-item__inner {
      max-height: 0;
      overflow: hidden;
      text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
      transition-duration: 0.5s;
      transition-property: max-height;
      z-index: 1;
      position: relative;
    }
    .accordion-item__content {
      opacity: 0;
      transform: translateY(-1rem);
      transition-timing-function: linear, ease;
      transition-duration: 0.1s;
      transition-property: opacity, transform;
      transition-delay: 0.2s;
      padding: 30px 0 0 0;
    }
    .accordion-item__paragraph {
      margin: 0;
    }

    .accordion-item__sub-link {
      color: ${theme.mainPrimary};
      padding: 0.4rem 0;
      margin: 0;
      display: block;
      width: 100%;
      text-decoration: none;
      border: none;
      text-align: left;
      font-style: normal;
      font-weight: 600;
      font-size:16px;
      letter-spacing: 1px;
      line-height: 120%;
      &:hover,
      &.active {
        color: ${theme.colorPrimary};
      }
    }
  `
  const DocLabel = styled.p`
    display: flex;
    line-height: 17px;
    margin: 0.5em;
    font-weight: 200;
    letter-spacing: 1px;
    font-size: ${dimensions.headingSizes.h4}px;
    margin: 14px 10px 9px 10px;
    font-family: 'CentraNo2Regular', sans-serif;
    text-transform:uppercase;
  `

  return (
    <StaticQuery
      query={graphql`
        query NavigationQuery {
          allMarkdownRemark(sort: { fields: frontmatter___order }) {
            nodes {
              id
              frontmatter {
                name
                route
                menu
                order
                title
              }
              headings(depth: h1) {
                value
                depth
              }
            }
          }
        }
      `}
      render={(data: Props) => {
        const [currIds, setCurrId] = React.useState(['']); // Enable when needed
        const { pathname, hash } = useLocation()
        const {
          allMarkdownRemark: { nodes }
        } = data

        // Enable when needed
        React.useEffect(() => {
          const item = nodes.find(n => n && utils.isSamePath(pathname, n.frontmatter.route))
          if (item) setCurrId([item?.id])
        }, [pathname])

        return (
          <StyledNavigation>
            <LogoLink to="/">
              <Logo alt="Sylo logo" style={{ maxWidth: 75 }} src={isDark ? logoWhite : logoBlue} />{' '}
              <DocLabel style={{ color: theme.mainPrimary }}>DOCS</DocLabel>
            </LogoLink>
            <AccordionList className="accordion-list">
              {nodes.map((n, key) => {
                const isOpen = currIds.includes(n.id); // Enable when needed
                const { name, route } = n.frontmatter;

                return (
                  <li className="accordion-list__item" key={key}>
                    <div className={!isOpen ? 'accordion-item' : 'accordion-item--opened'}>
                      <div className="accordion-item__line">
                        <Link className="accordion-item__button" to={route}>
                          <h3 className={`accordion-item__title ${utils.isSamePath(pathname, route) ? 'active' : ''}`}>{name}</h3>
                        </Link>
                      </div>
                      {n.headings.length && n.headings.length > 1 ? (
                        <div className="accordion-item__inner">
                          <div className="accordion-item__content">
                            {n.headings.map((h, i) => {
                              const hashNav = utils.createAnchorUrl(route, h.value)
                              const subHash = `#${utils.createAnchor(h.value)}`
                              const active = utils.isSamePath(pathname, route) && (hash === subHash);
                              return (
                                <Link key={i} className={`accordion-item__sub-link ${active && 'active'}`} to={hashNav}>
                                  {h.value}
                                </Link>
                              )
                            })}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </li>
                )
              })}
            </AccordionList>
          </StyledNavigation >
        )
      }}
    />
  )
}

export default Navigation
