import { css } from '@emotion/core'
import { dimensions, breakpoints, weights } from './variables'
import { getEmSize } from './mixins'

export const normalize = (theme: Record<string, string>) => {
  return css`
    html {
      box-sizing: border-box;
    }

    *,
    *::before,
    *::after {
      box-sizing: inherit;
    }

    body {
      width: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      font-family: 'LatoRegular', sans-serif;
      letter-spacing: 1px;
      font-weight: 400;
      font-style: normal;
      line-height: 180%;
      font-size: ${dimensions.fontSize.regular}px;
      color: ${theme.mainPrimary};
      background-color: ${theme.background};
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      margin: 2rem 0 ${dimensions.headingSizes.h4}px 0;
      margin-top:60px;
    }
    * {
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
    }
    a {
      color: ${theme.colorPrimary};
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    img {
      max-width: 100%;
      object-fit: contain;
      position: relative;
      border-radius: 8px;
    }

    figure {
      margin: 2rem 0;
    }

    figcaption {
      font-size: 80%;
    }

    table {
      width: 100%;
      margin-bottom: 1rem;
      font-size: 16px;
      border-collapse: collapse;
    }

    td,
    th {
      padding: 0.25rem 0.5rem;
      font-weight: 500;
      letter-spacing: 1px;
      font-size: ${dimensions.fontSize.regular};
      border: 1px solid ${theme.mainTertiary};
    }

    table thead th {
      font-weight: 700;
    }

    table tbody tr:nth-of-type(odd) {
      background-color: ${theme.cardDefault};
    }

    th {
      text-align: left;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: ${theme.mainPrimary};
      font-weight: 600;
      font-style: normal;
      line-height: 120%;
      text-rendering: optimizeLegibility;
      font-family: 'CentraNo2Bold', sans-serif;
    }

    h1 {
      letter-spacing: 1px;
      font-weight: 400;
      font-size: ${dimensions.headingSizes.h1}px;
      margin: 3rem 0 ${dimensions.headingSizes.h4}px 0;
    }

    h2 {
      letter-spacing: 1px;
      font-weight: 400;
      font-size: ${dimensions.headingSizes.h2}px;
      margin: 3rem 0 ${dimensions.headingSizes.h4}px 0;
    }

    h3 {
      letter-spacing: 1px;
      font-weight: 400;
      font-size: ${dimensions.headingSizes.h3}px;
      margin: 2rem 0 ${dimensions.headingSizes.h4}px 0;
      font-family: 'CentraNo2Bold', sans-serif;
    }

    h4 {
      font-weight: 600;
      letter-spacing: 1px;
      font-size: ${dimensions.headingSizes.h4}px;
      margin: 2rem 0 ${dimensions.headingSizes.h4}px 0;
      font-family: 'CentraNo2Regular', sans-serif;
    }

    h5 {
      letter-spacing: 4px;
      font-weight: 700;
      font-size: ${dimensions.headingSizes.h5}px;
      margin: 2rem 0 ${dimensions.headingSizes.h5}px 0;
    }

    h6 {
      letter-spacing: 2px;
      font-weight: 600;
      font-size: ${dimensions.fontSize.regular}px;
      margin: 2rem 0 ${dimensions.fontSize.regular}px 0;
    }

    p {
      margin: 0.5rem 0 1rem 0;
    }

    strong {
      font-weight: ${weights.med};
    }
    input {
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: ${theme.mainSecondary};
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        color: ${theme.mainSecondary};
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        color: ${theme.mainSecondary};
      }
      :-moz-placeholder {
        /* Firefox 18- */
        color: ${theme.mainSecondary};
      }
    }

    ul,
    ol,
    dl {
      margin-top: 0;
      margin-bottom: 1rem;
    }

    dt {
      font-weight: bold;
    }

    dd {
      margin-bottom: 0.5rem;
    }

    hr {
      position: relative;
      margin: 1.5rem 0;
      border: 0;
      border-top: 1px solid ${theme.mainTertiary};
    }

    td,
    th {
      padding: 0.25rem 0.5rem;
      border: 1px solid ${theme.cardSelected};
    }

    blockquote {
      margin: 0.8rem 0;
      padding: 0.5rem 1rem;
      border-left: 0.25rem solid ${theme.cardSelected};
      color: ${theme.mainTertiary};

      background-size: 12px 12px;
      background-position: 16px 16px;
      background-repeat: no-repeat;
      padding: 12px 12px 12px 40px;
      border: 1px solid ${theme.cardSelected};
      border-radius: 4px;
      background-color: ${theme.cardDefault};

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }

      @media (min-width: ${getEmSize(breakpoints.md)}em) {
        padding-right: 5rem;
        padding-left: 1.25rem;
      }
    }

    .timedHightlight {
      -webkit-transition: all 1s ease-in-out;
      -moz-transition: all 1s ease-in-out;
      -o-transition: all 1s ease-in-out;
      transition: all 1s ease-in-out;
      padding-left: 1em;
      padding: 0 3px;
      color: black;
      background: ${theme.colorPrimary};
      border-radius: 3px;
    }

    .main-layout {
      width: 100%;
      padding-left: ${dimensions.sideBar.width};
      display: inline-block;
      @media (max-width: ${getEmSize(breakpoints.md)}em) {
        padding-left: 0;
      }
    }

  `
}
